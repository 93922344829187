<!-- @Author: Yu_Bo -->
<template>
  <div class='roles_edit' v-loading='mainLoading'>
    <!-- 标题 -->
    <div class="vue_title_txt">
      <span class="title">角色管理</span>
      <span class="line">/</span>
      <span class="tip">编辑</span>
    </div>
    <!--  -->
    <div class="edit_main">
      <!--  -->
      <div class="input">
        <span class="dian">*</span>
        <span class="name">角色名称:</span>
        <el-input v-model="name" size="small" placeholder="请输入角色名称"></el-input>
      </div>
      <div class="btn">
        <div class="left">
          <span class="dian">*</span>
          <span class="name">配置权限:</span>
        </div>
        <div class="right">
          <el-checkbox v-model="allChecked" @change='allChangeBtn'>选择全部</el-checkbox>
          <el-button class="btnBgColor_blue" size="small" type="primary" :loading='loading'
            @click="preserveBtn">保存</el-button>
        </div>
      </div>
      <!--  -->
      <div class="info_data">
        <div class="data_title">
          <span class="span_one">一级</span>
          <span class="span_two">二级</span>
          <span>三级</span>
        </div>
        <div class="data_list" v-for="(item,index) in list" :key="index">
          <div class="div_one">
            <el-checkbox v-model="item.show" @change="oneChangeBtn(index,$event)">{{item.resname}}</el-checkbox>
          </div>
          <div class="div_two">
            <div class="two_list" v-if="!item.children || item.children.length==0">
              <div class="left"></div>
              <div class="right"></div>
            </div>
            <div class="two_list" v-for="(ite,idx) in item.children" :key="idx">
              <div class="left">
                <el-checkbox v-model="ite.show" @change="twoChangeBtn(index,idx,$event)">{{ite.resname}}</el-checkbox>
              </div>
              <div class="right">
                <div class="right_text" v-for="(ie,ix) in ite.children" :key="ix">
                  <el-checkbox v-model="ie.show"
                    @change="threeChangeBtn(index,idx,ix,$event)">{{ie.resname}}</el-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {
        id:'',
        name: '',
        ids:[],
        allChecked: false,
        list: [],
        //
        mainLoading:false,
        loading:false,
        //
        roleList:[],
      }
    },
    computed: {},
    watch: {
      'list':{
          deep:true,
          handler() {
            this.roleList=[]
            this.roleListInfo(this.list)
          }
        }
    },
    created() {
      if(this.$route.query.id){
        this.id=this.$route.query.id
        this.name=this.$route.query.name
        this.ids=this.$route.query.ids.split(',')
      }
      this.getList()
    },
    mounted() {},
    methods: {
      roleListInfo(arr){
        for(var i=0;i<arr.length;i++){
          if(arr[i].show){
            this.roleList.push(arr[i].id)
          }
          if(arr[i].children && arr[i].children.length){
            this.roleListInfo(arr[i].children)
          }
        }
      },
      // 选择全部
      allChangeBtn() {
        this.addShowBtn(this.list,this.allChecked)
      },
      addShowBtn(arr,show){
        arr.forEach(item=>{
          item.show=show
          if(item.children && item.children.length){
            this.addShowBtn(item.children,show)
          }
        })
      },
      // 递归赋值show
      recursiveBtn(arr,val){
        arr.forEach(item=>{
          item.show=val
          if(item.children && item.children.length){
            this.recursiveBtn(item.children,val)
          }
        })
      },
      // 一级
      oneChangeBtn(index,val) {
        if(this.list[index].children && this.list[index].children.length){
          this.recursiveBtn(this.list[index].children,val)
        }
        this.$forceUpdate()
      },
      // 二级
      twoChangeBtn(index,idx,val) {
        var arr = this.list[index].children
        var obj = arr.filter(item=>item.show)
        if(obj.length){
          this.list[index].show=true
        }else{
          this.list[index].show=false
        }
        if(this.list[index].children[idx].children && this.list[index].children[idx].children.length){
          this.recursiveBtn(this.list[index].children[idx].children,val)
        }
        this.$forceUpdate()
      },
      // 三级
      threeChangeBtn(index,idx,ix,val) {
        if(val){
          this.list[index].show=val
          this.list[index].children[idx].show=val
        }else{
          var arr = this.list[index].children[idx].children
          var obj = arr.filter(item=>item.show==true)
          if(obj.length){
            this.list[index].show=true
            this.list[index].children[idx].show=true
          }else{
            this.list[index].children[idx].show=false
            this.twoChangeBtn(index,idx,false)
          }
        }
        this.$forceUpdate()
      },
      // 保存
      preserveBtn(){
        var that = this
        if(that.name==''){
          this.$errMsg('请输入角色名称')
          return
        }else if(this.roleList.length==0){
          this.$errMsg('请分配该角色的权限')
          return
        }else{
          that.loading = true
          var params = {
            id:that.id,
            menu_ids:that.roleList.join(','),
            name:that.name
          }
          that.$userApi.adminUpdate(params).then(res => {
            that.loading = false
            if (res.code == 1000) {
              that.$succMsg(res.message)
              setTimeout(()=>{
                that.$router.back()
              },2000)
            } else {
              that.$errMsg(res.message)
            }
          })
        }
        console.log(this.ids)
        console.log(this.roleList)
      },
      // 获取权限树
      getList(){
        var that = this
        that.mainLoading=true
        that.$userApi.memberRoleTree().then(res => {
          that.mainLoading=false
          if (res.code == 1000) {
            that.list=res.result
            that.showBtn(that.list,that.ids,1,2)
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 赋值权限选中状态
      showBtn(arr,str,index,type){
        var types=type
        arr.forEach(item=>{
          var obj = str.find(res=>res==item.id)
          if(obj){
            item.show=true
          }else{
            item.show=false
            types=1
          }
          if(types==1){
            this.allChecked=false
          }else{
            this.allChecked=true
          }
          if(item.children && item.children.length){
            this.showBtn(item.children,this.ids,index++,types)
          }
        })
      },
    },
  }
</script>

<style lang='scss' scoped>
  .roles_edit {
    width: 100%;

    .edit_main {
      width: 100%;
      padding: 0 20px 20px;

      .input {
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;

        .dian {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 600;
          color: #DA1616;
        }

        .name {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333;
          margin: 0 20px 0 5px;
        }

        .el-input {
          width: 224px;
        }
      }

      .btn {
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;

          .dian {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #DA1616;
          }

          .name {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333;
            margin: 0 20px 0 5px;
          }
        }

        .right {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .el-button {
            margin-left: 30px;
          }
        }
      }
      .info_data{
        width: 100%;
        margin-top: 15px;
        border: 1px solid #E9E9E9;
        border-radius: 2px;
        .data_title{
          width: 100%;
          height: 50px;
          background: #F0F2F5;
          display: flex;
          span{
            display: block;
            padding: 0 20px;
            line-height: 50px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            border-right: 1px solid #EDEDED;
          }
          span:last-child{
            border-right: none;
          }
          .span_one{
            width: 150px;
          }
          .span_two{
            width: 200px;
          }
        }
        .data_list{
          width: 100%;
          display: flex;
          .div_one{
            width: 150px;
            padding: 0 20px;
            border-right: 1px solid #EDEDED;
            border-bottom: 1px solid #EDEDED;
            display: flex;
            align-items: center;
          }
          .div_two{
            width: calc(100% - 150px);
            .two_list{
              display: flex;
              .left{
                width: 200px;
                padding: 0 20px;
                border-right: 1px solid #EDEDED;
                border-bottom: 1px solid #EDEDED;
                display: flex;
                align-items: center;
              }
              .right{
                width: calc(100% - 200px);
                min-height: 60px;
                padding: 5px 20px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                border-bottom: 1px solid #EDEDED;
                .right_text{
                  padding:5px 20px 5px 0;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
        .data_list:last-child{
          .div_one{
            border-bottom: none;
          }
          .div_two:last-child{
            .two_list:last-child{
              .left{
                border-bottom: none;
              }
              .right{
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
</style>
